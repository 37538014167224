<template>
  <div class="card" :style="{'background-color': backgroundcolor, 'color': fontcolor, 'height': '250px'}">
      <div class="card-header text-center">
        <slot name="header">
          <h5 class="card-title">{{title}}</h5>
          <p class="card-category" style="{'color': fontcolor !important; }">{{description}}</p>
        </slot>
      </div>
      <div class="card-body">
      </div>
      <div class="card-footer">
        <slot name="footer">
          <div v-if="enabled == '1'">
            <router-link :to="link" class="text-center" style="color:white; text-decoration:underline">
              <div v-if="status == 'Completed'">
                <h3>Udfyldt</h3>
              </div>
              <div v-else>
                <h3>Åben</h3>
              </div>
            </router-link>
          </div>
          <div v-else class="text-center">
            <h3>Åbner senere</h3>
          </div>

        </slot>
      </div>
  </div>
</template>
<script>
  import DoughnutChart from '../Charts/DoughnutChart'

  export default {
    name: 'circle-chart-card',
    components: {
      DoughnutChart,
    },
    props: {
      title: {
        type: String,
        description: 'Chart card title'
      },
      description: {
        type: String,
        description: 'Chart card description'
      },
      backgroundcolor: {
        type: String,
        default: '#FFFFFF',
        description: 'Background color'
      },
      fontcolor: {
        type: String,
        default: '#000000',
        description: 'font color'
      },
      status: {
        type: String,
        default: '',
      },
      enabled: {
        type: Boolean,
        default: "false"
      },
      labels: {
        type: Array,
        default: () => {
          return [1, 2]
        },
        description: 'Labels for each chart section'
      },
      link: {
        type: String,
        default: ''
      },
    },
  }
</script>
<style>
  text-decoration-line
  .ClosedSession {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
    background-color: white;
    width: 210px;
    margin-bottom: 90px;
    bottom: 0px;
    /*    padding: 10px;

    position: absolute;
    bottom: 0px;
    */
  }
  
</style>
