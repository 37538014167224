<template>
  <div :style="{'background-color': backgroundcolor, 'color': fontcolor, 'text-align': 'center'}">
    <h5>
      Hej {{Username}}
    </h5>
    <span v-html="Content"></span>
      </div>
</template>
<script>

  import Communication from 'src/communication/Communication.vue';

  export default {
    name: 'welcome-card',
    props: {
      backgroundcolor: {
        type: String,
        default: '#FFFFFF',
        description: 'Background color'
      },
      fontcolor: {
        type: String,
        default: '#000000',
        description: 'font color'
      },
    },
    data() {
      return {
        recieveRespons: {},
        Username: 'Demo - User',
        Content: '',
      }
    },
    async created() {
      await Communication.GetUserInformation().
        then(response => {
          this.recieveRespons = response;
          this.Username = response.currentUser.name;

          Communication.GetCommand('api/Exercise/GetActiveWelcomePage')
            .then(response => {
              this.Content = response.content;
            });
        })
        .catch(error => console.log(error));

      
      
    },
  }


</script>
<style>
</style>
