<template>
  <div class="card" :style="{'background-color': backgroundcolor, 'color': fontcolor}">
    <div class="card-header text-center">
      <slot name="header">
        <h5 class="card-title">{{title}}</h5>
        <p class="card-category" style="{'color': fontcolor !important}">{{description}}</p>
      </slot>
    </div>
    <div class="card-body">
    </div>   
  </div>
</template>
<script>

  export default {
    name: 'text-card',
    props: {
      color: {
        type: String,
        default: '#4acccd',
        description: 'Chart color'
      },
      secondaryColor: {
        type: String,
        default: '#f4f3ef',
        description: 'Secondayr color'
      },
      percentage: {
        type: Number,
        default: 0,
        description: 'Chart percentage'
      },
      title: {
        type: String,
        description: 'Chart card title'
      },
      description: {
        type: String,
        description: 'Chart card description'
      },
      enabled: {
        type: Boolean,
        description: 'Is Module enabled'
      },
      backgroundcolor: {
        type: String,
        default: '#FFFFFF',
        description: 'Background color'
      },
      fontcolor: {
        type: String,
        default: '#000000',
        description: 'font color'
      },
      labels: {
        type: Array,
        default: () => {
          return [1, 2]
        },
        description: 'Labels for each chart section'
      },
      chartId: String,
    },
    computed: {
      chartData() {
        return [this.percentage, 100 - this.percentage]
      }
    }
  }
</script>
<style>
</style>
