<template>
  <div class="card" :style="{'background-color': backgroundcolor, 'color': fontcolor}">
    <div class="card-header text-center">
      <slot name="header">
        <h5 class="card-title">{{title}}</h5>
        <p class="card-category" style="{'color': fontcolor !important}">{{description}}</p>
      </slot>
    </div>
    <div class="card-body">
      <div v-for="rows in badges">
        <div v-if="rows.Offset=='true'">
          <div class="row">
            <div class="offset-3 col-6 offset-3" v-for="items in rows.Rows">
              <div class="text-center" style="height: 100%">
                <img :src="items.image" alt="items.alt" />
              </div>
            </div>
          </div>
          </div>
          <div v-else >
            <div class="row">
              <div class="col-6" v-for="items in rows.Rows">
                <div class="text-center" style="height: 100%">
                  <img :src="items.image" alt="items.alt" />
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
    
  </div>
</template>
<script>

  export default {
    name: 'badge-card',
    props: {
      title: {
        type: String,
        description: 'Chart card title'
      },
      description: {
        type: String,
        description: 'Chart card description'
      },
      backgroundcolor: {
        type: String,
        default: '#FFFFFF',
        description: 'Background color'
      },
      fontcolor: {
        type: String,
        default: '#000000',
        description: 'font color'
      },      
      id: {
        String,
      },
      badges: [],
    },
    
  
  computed: {

  }
  }
</script>
<style>
  .badgeIcon {
    width:60%
  }
</style>
