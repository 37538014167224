<template>
  <div>
    <!--Stats cards-->
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6 d-flex align-items-stretch" v-for="stats in statsCards">
        <stats-card :type="stats.type"
                    :icon="stats.icon"
                    :small-title="stats.title"
                    :title="stats.title"
                    :badge_bg_color="stats.badge_bg_color"
                    :badge_color="stats.badge_color"
                    :text_bg_color="stats.text_bg_color"
                    :text_color="stats.text_color"
                    :image="stats.image"
                    :description="stats.description">
        </stats-card>
      </div>
    </div>

    <div class="row">
      <div class="col-10">
        <div class="card" style="background-color:#cc8899">
          <div class="card-body">
            <div class="row">
              <!--<div class="offset-1 col-lg-6 co l-sm-5 d-flex align-items-stretch justify-content-center">
                <video-card backgroundcolor="#CC8899"
                            fontcolor="#FFFFFF">
                </video-card>
              </div>
              <div class="offset-1 col-lg-3 col-sm-3 d-flex align-items-stretch">

                <welcome-card :name="welcomeCardData.UserName"
                              backgroundcolor="#CC8899"
                              fontcolor="#FFFFFF">

                </welcome-card>
              </div>-->
              <div class="col-6">
                <video-card backgroundcolor="#CC8899" style="padding-left:3%"
                            fontcolor="#FFFFFF">
                </video-card>
              </div>
              <div class="col-6">
                <welcome-card :name="welcomeCardData.UserName"
                              backgroundcolor="#CC8899" style="padding-left:3%"
                              fontcolor="#FFFFFF">

                </welcome-card>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-2 d-flex align-items-stretch">
        <badge-card id="BadgeOverview"
                    title="Badges"
                    backgroundcolor="#4D4C4C"
                    fontcolor="#FFFFFF"
                    :badges="userData.Badges">
        </badge-card>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-2 col-sm-2 align-items-stretch" v-for="session in sessions">
        <div v-if="session.isActive">
          <div @click="openSession(session.moduleNumber, session.sessionNumber, session.layout)" style="cursor:pointer">
            <circle-chart-card :title="session.mainTitle"
                               :description="session.subTitle"                              
                               :enabled="session.isActive"
                               :backgroundcolor="session.backgroundColor"
                               fontcolor="#ffffff"
                               :status="session.status"
                               :moduleId="session.moduleNumber"
                               :sessionId="session.sessionNumber"
                               >
            </circle-chart-card>
          </div>
        </div>
        <div v-else>
          <circle-chart-card :title="session.mainTitle"
                             :description="session.subTitle"
                             :enabled="session.isActive"
                             :backgroundcolor="session.backgroundColor"
                             fontcolor="#ffffff"
                             :status="session.status"
                             :moduleId="session.moduleNumber"
                             :sessionId="session.sessionNumber">
          </circle-chart-card>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CircleChartCard from 'src/components/UIComponents/Cards/CircleChartCard.vue';
  import TextCard from 'src/components/UIComponents/Cards/TextCard.vue';
  import WelcomeCard from 'src/components/UIComponents/Cards/WelcomeCard.vue'
  import VideoCard from 'src/components/UIComponents/Cards/VideoCard.vue'
  import BadgeCard from 'src/components/UIComponents/Cards/BadgeCard.vue';
  import ChartCard from 'src/components/UIComponents/Cards/ChartCard';
  import StatsCard from 'src/components/UIComponents/Cards/StatsCard';
  import { Badge } from 'src/components/UIComponents'
  import TaskList from './Widgets/TaskList'
  import Communication from 'src/communication/Communication.vue';

  export default {

    components: {
      StatsCard,
      ChartCard,
      TextCard,
      WelcomeCard,
      VideoCard,
      BadgeCard,
      CircleChartCard,
      Badge,
      TaskList
    },
    /**
     * Chart data used to render stats, charts. Should be replaced with server data
     */
    async mounted() {
      var result = await Communication.GetCommand('api/Exercise/GetHeadlinesForExercises')
        .then(response => {
          this.sessions = response;
        })
      
    },
    methods: {
      openSession(moduleNumber, sessionNumber, layoutNumber) {
        console.log(layoutNumber);
        localStorage['PageData'] = JSON.stringify({
          moduleNumber: moduleNumber,
          sessionNumber : sessionNumber
        })
        if (layoutNumber == 1)
          this.$router.push('/layoutPage_1');
        else if (layoutNumber == 3)
        {
          this.$router.push('/layoutPage_2');
        }

      },
    },
    data() {
      return {
        welcomeCardData: {
          UserName: 'Nothing'
        },
        sessions: [],
        WelcomePage: {},
        userData: {
          Badges: [
            {
              Offset: 'false',
              Rows: [
                {
                  type: 'Alumni',
                  image: 'img/badges/badge-alumni.png',
                  active: 'true'
                },
                {
                  type: 'Thriving',
                  image: 'img/badges/badge-iwant.png',
                  active: 'true'
                }],

            },
            {
              Offset: 'true',
              Rows: [
                {
                  type: 'PayIt',
                  image: 'img/badges/badge-trophy.png',
                  active: 'true',
                },
              ],
            },
            {
              Offset: 'false',
              Rows: [
                {
                  type: 'VentureBuilder',
                  image: 'img/badges/badge-venturebuilder_locked.png',
                  alt: 'Future badge',
                  active: 'false',
                },
                {
                  type: 'Mentor',
                  image: 'img/badges/badge-mentor_locked.png',
                  alt: 'Future badge',
                  active: 'false',
                }
              ],
            },

          ],
          Coach: {

          }
        },
        statsCards: [
          {
            type: 'info',
            icon: 'nc-icon nc-favourite-28',
            image: 'img/badges/badge-alarm.png',
            title: '112 Knap',
            description: 'Hvis du er kørt fast og har brug for hjælp',
            badge_bg_color: 'white',
            bagde_color: 'red',
            text_bg_color: 'red',
            text_color: 'white'
          },
          {
            type: 'warning',
            icon: 'nc-icon nc-globe',
            title: '"Thriving Alumni"',
            image: 'img/badges/badge-alumni.png',
            description: 'Med din deltagelse er du en del af den fremtidige rejse sammen med de mange andre i Alumni Thriving',
            badge_bg_color: '#4D4C4C',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'
          },
          {
            type: 'success',
            icon: 'nc-icon nc-money-coins',
            title: '"I want to Thrive"',
            image: './img/badges/badge-iwant.png',
            description: 'Tillykke med at du er i gang med et forløb om at få et arbejdsliv i Stortrivsel',
            badge_bg_color: '#4D4C4C',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'

          },
          {
            type: 'danger',
            icon: 'nc-icon nc-vector',
            title: '"Pay it forward now"',
            image: 'img/badges/badge-trophy.png',
            description: 'Tak - en del af din betaling er resereveret til en samlet pulje der kommer alle til gode',
            badge_bg_color: '#4D4C4C',
            bagde_color: '',
            text_bg_color: '#',
            text_color: '#'

          }
        ],

      }
    }
  }

</script>
<style>
</style>
