<template>
  <div :style="{'background-color': backgroundcolor, 'color': fontcolor, 'padding': '5px'}">
        <video id="video" controls>
          <source v-if="videoUrl" :src="videoUrl" type="video/mp4">
        </video>
      <!--<a href="documents\Program_Thriving.pdf" style="color:white">Link til hele programmet - Tryk her</a>-->
    </div>
</template>
<script>

  import Communication from 'src/communication/Communication.vue';
  export default {
    name: 'video-card',
    props: {
      backgroundcolor: {
        type: String,
        default: '#FFFFFF',
        description: 'Background color'
      },
      fontcolor: {
        type: String,
        default: '#000000',
        description: 'font color'
      },
    },
    data() {
      return {
        videoUrl: '',
      }
    },
    async mounted() {
      await Communication.GetCommand('api/Exercise/GetActiveWelcomePage')
        .then(response => {
          this.videoUrl = response.videoUrl;
        }).catch(error => {
          console.log(error);
        });      
    }
  }

</script>
<style>
  #video {
    width: 100%
  }
</style>
